.pokecard {
  width: 200px;
  height: 250px;
  max-height: max-content;
  border: 2px solid #3469b4;
  display: flex;
  font-size: 0.8em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  display: inline-flex;
  border-radius: 50px;
  background: #fae9aa;
  box-shadow: -20px 20px 60px #e0e0e0, 20px -20px 60px #ffffff;
}
.statsbtn {
  border: 0.5px solid #3469b4;
  border-radius: 5px;
  background-color: #bdbdbd;
}
.statsbtn:hover {
  border: 0.5px solid rgb(116, 7, 7);
}
