.Pokedex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Unbounded", cursive;
}

.LoadingScreen {
  display: flex;
  height: 100px;
  align-self: center;
  justify-self: center;
}

h1 {
  min-height: min-content;
}

.PokemonLogo {
  height: 10%;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title > h1 {
  width: min-content;
}

.counter {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  row-gap: 20px;
}

.counter__output {
  font-size: 40px;
  color: rgb(116, 7, 7);
}

.btn__container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 20px;
}

.control__btn {
  font-size: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: rgb(16, 0, 54);
  border: 1px solid rgb(16, 0, 54);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.control__btn:hover {
  background-color: rgb(16, 0, 54);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(16, 0, 54);
}

.btn__container {
  display: flex;
  justify-items: center;
}
